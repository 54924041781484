import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {
  Container,
  Row,
  Col,
  Card,
  Breadcrumb,
  Form,
  Button,
  Table,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import "./LedgerReport.css";
import axios from "axios";
import { useSelector } from "react-redux";

const LedgerReports = () => {
  const [items, setItems] = useState([]); 
  const [filteredItems, setFilteredItems] = useState([]); 
  const [currentPage, setCurrentPage] = useState(1); 
  const [itemsPerPage] = useState(20); 
  const [fromDate, setFromDate] = useState(null); 
  const [toDate, setToDate] = useState(null);
  const [referenceId, setReferenceId] = useState(""); 
  const { walletData } = useSelector((state) => state.auth);


  useEffect(() => {
    const fetchLedgerData = async () => {
      try {
        const request = { AgentId: walletData.Id };
        const response = await axios.post(
          "https://admin.yatriservice.com/api/ledgerReport",
          request
        );
        console.log("RESPONSE IN LEDGER",response.data.data)
        if (response.data.success) {
          setItems(response.data.data);
          setFilteredItems(response.data.data.reverse()); 
        } else {
          console.error("Failed to fetch data:", response.data.message);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchLedgerData();
  }, [walletData.Id]);


const formatDateTime = (dateTimeString) => {
  const dateObject = new Date(dateTimeString);
  const hours = dateObject.getHours();
  const minutes = dateObject.getMinutes();
  const ampm = hours >= 12 ? "PM" : "AM";
  const formattedTime = `${hours % 12 || 12}:${String(minutes).padStart(
    2,
    "0"
  )} ${ampm}`;
  const formattedDate = `${String(dateObject.getMonth() + 1).padStart(
  2,
  "0"
  )}-${String(dateObject.getDate()).padStart(
    2,
    "0"
  )}-${dateObject.getFullYear()}`;
    return `${formattedTime} ${formattedDate}`;
  };


  const handleSearchByDateRange = (event) => {
    event.preventDefault();
    const filteredData = items.filter((ledger) => {
      const ledgerDate = new Date(ledger.created_at);
      const isDateInRange =
        (!fromDate || ledgerDate >= fromDate) &&
        (!toDate || ledgerDate <= toDate);

      const isReferenceIdMatch =
        !referenceId ||
        (ledger.reference_id &&
          ledger.reference_id.includes(referenceId.trim()));

      return isDateInRange && isReferenceIdMatch;
    });
    setFilteredItems(filteredData);
    setCurrentPage(1); 
  };

 
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredItems.slice(indexOfFirstItem, indexOfLastItem);
  const totalPages = Math.ceil(filteredItems.length / itemsPerPage);


  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };


// const datass=[...currentItems]
// console.log("DATATSSAS:",datass)
  return (
    <div className="content-wrapper new_markup">
      <Container fluid>
        <div className="content-header">
          <Container fluid>
            <Row>
              <Col className="sm-center">
                <h1 className="m-0 text-dark">Ledger Report</h1>
              </Col>
              {/* <Col sm={6} md={6} className="sm-center">
                <Breadcrumb className="float-sm-right">
                  <Breadcrumb.Item
                    linkAs={Link}
                    linkProps={{ to: "/agent/dashboard" }}
                  >
                    <span style={{ color: "#fff" }}> Home</span>
                  </Breadcrumb.Item>
                  <Breadcrumb.Item active>Ledger Report</Breadcrumb.Item>
                </Breadcrumb>{" "}
              </Col> */}
            </Row>
          </Container>
        </div>
        <section className="content">
          <Container fluid>
            <Row>
              <Col md={12}>
                <Card>
                  <Card.Body>
                    <div className="ledger_report common_report">
                      <div className="ledger_inner">
                        <div className="cus_report_field">
                          <Form onSubmit={handleSearchByDateRange}>
                            <Row>
                              <Col sm={3}>
                                <Form.Group>
                                  <Form.Label>Reference ID</Form.Label>
                                  <Form.Control
                                    autoComplete="off"
                                    placeholder="Enter Ref ID"
                                    type="text"
                                    value={referenceId}
                                    onChange={(e) =>
                                      setReferenceId(e.target.value)
                                    }
                                  />
                                </Form.Group>
                              </Col>
                              <Col sm={3}>
                                <Form.Group>
                                  <Form.Label>Submission Start Date</Form.Label>
                                  <DatePicker
                                    selected={fromDate}
                                    onChange={(date) => setFromDate(date)}
                                    dateFormat="yyyy-MM-dd"
                                    className="form-control"
                                    maxDate={toDate || new Date()}
                                  />
                                </Form.Group>
                              </Col>
                              <Col sm={3}>
                                <Form.Group>
                                  <Form.Label>Submission End Date</Form.Label>
                                  <DatePicker
                                    selected={toDate}
                                    onChange={(date) => setToDate(date)}
                                    dateFormat="yyyy-MM-dd"
                                    className="form-control"
                                    minDate={fromDate}
                                    maxDate={new Date()}
                                  />
                                </Form.Group>
                              </Col>
                              <Col
                                sm={3}
                                style={{ textAlign: "end", marginTop: "25px" }}
                              >
                                <div className="ledger_btn">
                                  <Button type="submit" className="cus_btn">
                                    Search logs
                                  </Button>
                                </div>
                              </Col>
                            </Row>
                          </Form>
                        </div>
                        <Row>
                          <Col sm={12}>
                            <div className="table-responsive">
                              <Table bordered hover className="dataTable">
                                <thead>
                                  <tr>
                                    <th>Date</th>
                                    <th>Ref. Number</th>
                                    <th>Particulars</th>
                                    <th >Credit</th>
                                    <th className="text-right">Debit</th>
                                    <th className="text-right">Credit Limit</th>
                                    <th className="text-right">
                                      Running Balance
                                    </th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {currentItems.map((ledger, index) => (
                                    <tr key={index}>
                                      <td style={{fontWeight:'500'}}>
                                        {formatDateTime(ledger.created_at)}
                                      </td>
                                      <td>{ledger.reference_id}</td>
                                      <td>{ledger.remark}</td>
                                      <td  style={{color:"#008000",fontWeight:'500'}}>
                                        ₹{ledger.credit}
                                      </td>
                                      <td className="text-right table-textt-red" style={{fontWeight:'500'}}>
                                        ₹{ledger.debit}
                                      </td>
                                      <td className="text-right" style={{color:"#008000",fontWeight:'500'}}>
                                        ₹{ledger.credit_limit}
                                      </td>
                                      <td className="text-right">
                                        <b>₹{ledger.running_balance}</b>
                                      </td>
                                    </tr>
                                  ))}
                                </tbody>
                              </Table>
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col sm={12} className="text-center">
                            <div
                              className="pagination"
                              style={{ justifyContent: "end" }}
                            >
                              <div
                                style={{ fontWeight: "600", fontSize: "16px" }}
                              >
                                Pages :{" "}
                              </div>
                              {Array.from(
                                { length: totalPages },
                                (_, index) => (
                                  <Button
                                    key={index + 1}
                                    onClick={() => handlePageChange(index + 1)}
                                    className={`mx-1 ${
                                      currentPage === index + 1 ? "active" : ""
                                    } btn-for-pagginationss`}
                                  >
                                    {index + 1}
                                  </Button>
                                )
                              )}
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </div>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Container>
        </section>
      </Container>
    </div>
  );
};

export default LedgerReports;
