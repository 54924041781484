// import  from "./FlightDetails.module.css";
// import './FlightDetails.css'
import "../../OneWay/FlightListInfo.css";
// import axios from "axios";
import { useState } from "react";
import Box from "@mui/material/Box";
// import { } from '@mui/material/Tab';
// import { TabContext } from '@mui/lab/TabContext';
// import { TabList } from '@mui/lab/TabList';
// import { TabPanel } from '@mui/lab/TabPanel';
import flightDetails1 from "../../OneWay/images/flightDetails1.PNG";
import arrowForward from "../../OneWay/images/arrowForward.png";
import clock from "../../OneWay/images/clock.png";
import TabContext from "@mui/lab/TabContext";
import TabPanel from "@mui/lab/TabPanel";
import Tab from "@mui/material/Tab";
import TabList from "@mui/lab/TabList";
import { Col, Container, Row } from "react-bootstrap";
import { HiMiniHandThumbUp } from "react-icons/hi2";

export const formatTime = (arrTime) => {
  const date = new Date(arrTime);
  const days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  const day = days[date.getDay()];
  const dateNum = String(date.getDate()).padStart(2, "0");
  const month = months[date.getMonth()];
  const year = date.getFullYear();
  return `${day}-${dateNum} ${month} ${year}`;
};

export const InternationalFlightDetail = ({
  idx,
  flight,
  netFare,
  agentIm,
  adminIm,
  fareRules,
  internationalMarkup,
}) => {
  const [value, setValue] = useState("1");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const formatLayoverTime = (arrivalTime, nextDepartureTime) => {
    const arrival = new Date(arrivalTime);
    const nextDeparture = new Date(nextDepartureTime);
    const layoverMinutes = Math.floor(
      (nextDeparture.getTime() - arrival.getTime()) / (1000 * 60)
    );
    const layoverHours = Math.floor(layoverMinutes / 60);
    const layoverMinutesRemainder = layoverMinutes % 60;
    return `${layoverHours}h : ${layoverMinutesRemainder}m`;
  };
  console.log("FLIGHT DETAIL PAGE", flight);
  return (
    <>
      {idx === flight.ContractId ? (
        <div className="detailDiv">
          <Box sx={{ width: "100%", typography: "body1" }}>
            <TabContext value={value}>
              <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <TabList
                  onChange={handleChange}
                  aria-label="lab API tabs example"
                >
                  <Tab
                    label="Flight Information"
                    value="1"
                    sx={{
                      fontSize: "16px",
                      paddingRight: "15px",
                      fontSize: "12px",
                    }}
                    className="tabFlightData"
                  />
                  <Tab
                    label="Fare Details And Rules"
                    value="2"
                    sx={{
                      fontSize: "16px",
                      paddingRight: "15px",
                      marginLeft: "25px",
                      fontSize: "12px",
                    }}
                  />
                  <Tab
                    label="Baggage Information"
                    value="3"
                    sx={{
                      fontSize: "16px",
                      paddingRight: "15px",
                      marginLeft: "10px",
                      fontSize: "12px",
                    }}
                  />
                  <Tab
                    label="Cancellation And Change Rules"
                    value="4"
                    sx={{
                      fontSize: "16px",
                      paddingRight: "15px",
                      marginLeft: "10px",
                      fontSize: "12px",
                    }}
                  />
                </TabList>
              </Box>
              <TabPanel value="1" style={{ padding: "5px" }}>
                <div className="block-content-2 custom_block_content">
                  <div className="box-result custom_box_result">
                    <div className="flight_tags depart_tags">
                      <span style={{ zIndex: 2 }}>Departure</span>
                    </div>
                    {flight.AirSegments[0].map((e, indexx) => (
                      <div key={indexx} style={{ marginBlock: "10px" }}>
                        <div className="FlightInfoDetailsMain">
                          <p className="flightInfoDetailsPara">
                            {e.Origen} → {e.Destination}
                          </p>
                          <img
                            src={arrowForward}
                            alt=""
                            className="flightInfoImageMain"
                          />
                          <p className="flightInfoDetailsPara">
                            {e.AirlineCode}-{e.FlightNumber}
                          </p>
                        </div>
                        <div className="flightInforDetailsMain2">
                          <div
                            className="imgdiv"
                            style={{
                              display: "flex",
                              flexWrap: "wrap",
                              width: "15%",
                            }}
                          >
                            <img
                              src={`/Images/AirlineLogo/${e.AirlineCode}.gif`}
                              alt=""
                              className="flightInfoImagetag"
                            />
                            <div className="flightInfoDivtag1">
                              <p
                                className="flightInfoDivPara1"
                                style={{ margin: "0px" }}
                              >
                                {e.AirlineName}
                              </p>
                              <p className="flightInfoPara4">
                                {e.FlightNumber}
                              </p>
                            </div>
                          </div>
                          <div className="flightInfoMainDiv2">
                            <p className="flightInfoPara2">
                              {new Date(e.DepartureDateTime).toLocaleTimeString(
                                [],
                                {
                                  hour: "2-digit",
                                  minute: "2-digit",
                                  hour12: false,
                                }
                              )}
                            </p>
                            <p className="flightInfoPara3">
                              {e.Origen}{" "}
                              {new Date(e.DepartureDateTime).toLocaleTimeString(
                                [],
                                {
                                  hour: "2-digit",
                                  minute: "2-digit",
                                }
                              )}
                            </p>
                            <p className="flightInfoPara4">
                              {formatTime(e.DepartureDateTime)}
                            </p>
                            <p className="flightInfoPara4">
                              {e.SourceAirport.airport_name}
                            </p>
                            {e.OriginAirportTerminal && (
                              <p className="flightInfoPara4">
                                Terminal - {e.OriginAirportTerminal}
                              </p>
                            )}
                          </div>
                          <div className="flightInfoMainDiv5">
                            <img
                              src={clock}
                              alt=""
                              className="flightInfoImage2"
                            />
                            <p className="flightInfoPara12">{e.Duration}</p>
                          </div>
                          <div
                            className="flightInfoMainDiv2"
                            //  style={{ marginLeft: "180px", paddingTop: "0px" }}
                          >
                            <p className="flightInfoPara2">
                              {" "}
                              {new Date(e.ArrivalDateTime).toLocaleTimeString(
                                [],
                                {
                                  hour: "2-digit",
                                  minute: "2-digit",
                                  hour12: false,
                                }
                              )}
                            </p>
                            <p className="flightInfoPara3">
                              {" "}
                              {e.Destination}{" "}
                              {new Date(e.ArrivalDateTime).toLocaleTimeString(
                                [],
                                {
                                  hour: "2-digit",
                                  minute: "2-digit",
                                }
                              )}
                            </p>
                            <p className="flightInfoPara4">
                              {formatTime(e.ArrivalDateTime)}
                            </p>
                            <p className="flightInfoPara4">
                              {e.DestinationAirport.airport_name}
                            </p>
                            {e.DestinationAirportTerminal && (
                              <p className="flightInfoPara4">
                                Terminal - {e.DestinationAirportTerminal}
                              </p>
                            )}
                            {/* {e.Destination.Airport.Terminal && (
                              <p className="flightInfoPara4">
                                Terminal - {e.Destination.Airport.Terminal}
                              </p>
                            )} */}
                          </div>
                        </div>
                        {indexx < flight.AirSegments[0].length - 1 && (
                          // <div className="inner_booking">
                          <div
                            className="layover_time"
                            style={{ marginBlock: "20px" }}
                          >
                            <div className="layover_txt">
                              Layover:
                              <span>
                                {formatLayoverTime(
                                  e.ArrivalDateTime,
                                  flight.AirSegments[0][indexx + 1]
                                    .DepartureDateTime
                                )}
                              </span>
                            </div>
                          </div>
                          // </div>
                        )}
                      </div>
                    ))}
                  </div>
                </div>
                <div className="block-content-2 custom_block_content">
                  <div className="box-result custom_box_result">
                    <div className="flight_tags depart_tags">
                      <span style={{ zIndex: 2 }}>Return</span>
                    </div>
                    {flight.AirSegments[1].map((e, indexx) => (
                      <div key={indexx} style={{ marginBlock: "10px" }}>
                        <div className="FlightInfoDetailsMain">
                          <p className="flightInfoDetailsPara">
                            {e.Origen} → {e.Destination}
                          </p>
                          <img
                            src={arrowForward}
                            alt=""
                            className="flightInfoImageMain"
                          />
                          <p className="flightInfoDetailsPara">
                            {e.AirlineCode}-{e.FlightNumber}
                          </p>
                        </div>
                        <div className="flightInforDetailsMain2">
                          <div
                            className="imgdiv"
                            style={{
                              display: "flex",
                              flexWrap: "wrap",
                              width: "15%",
                            }}
                          >
                            <img
                              src={`/Images/AirlineLogo/${e.AirlineCode}.gif`}
                              alt=""
                              className="flightInfoImagetag"
                            />
                            <div className="flightInfoDivtag1">
                              <p
                                className="flightInfoDivPara1"
                                style={{ margin: "0px" }}
                              >
                                {e.AirlineName}
                              </p>
                              <p className="flightInfoPara4">
                                {e.FlightNumber}
                              </p>
                            </div>
                          </div>
                          <div className="flightInfoMainDiv2">
                            <p className="flightInfoPara2">
                              {new Date(e.DepartureDateTime).toLocaleTimeString(
                                [],
                                {
                                  hour: "2-digit",
                                  minute: "2-digit",
                                  hour12: false,
                                }
                              )}
                            </p>
                            <p className="flightInfoPara3">
                              {e.Origen}{" "}
                              {new Date(e.DepartureDateTime).toLocaleTimeString(
                                [],
                                {
                                  hour: "2-digit",
                                  minute: "2-digit",
                                }
                              )}
                            </p>
                            <p className="flightInfoPara4">
                              {formatTime(e.DepartureDateTime)}
                            </p>
                            <p className="flightInfoPara4">
                              {e.SourceAirport.airport_name}
                            </p>
                            {e.OriginAirportTerminal && (
                              <p className="flightInfoPara4">
                                Terminal - {e.OriginAirportTerminal}
                              </p>
                            )}
                            {/* {e.Origin.Airport.Terminal && (
                              <p className="flightInfoPara4">
                                Terminal - {e.Origin.Airport.Terminal}
                              </p>
                            )} */}
                          </div>
                          <div className="flightInfoMainDiv5">
                            <img
                              src={clock}
                              alt=""
                              className="flightInfoImage2"
                            />
                            <p className="flightInfoPara12">{e.Duration}</p>
                          </div>
                          <div
                            className="flightInfoMainDiv2"
                            //  style={{ marginLeft: "180px", paddingTop: "0px" }}
                          >
                            <p className="flightInfoPara2">
                              {" "}
                              {new Date(e.ArrivalDateTime).toLocaleTimeString(
                                [],
                                {
                                  hour: "2-digit",
                                  minute: "2-digit",
                                  hour12: false,
                                }
                              )}
                            </p>
                            <p className="flightInfoPara3">
                              {" "}
                              {e.Destination}{" "}
                              {new Date(e.ArrivalDateTime).toLocaleTimeString(
                                [],
                                {
                                  hour: "2-digit",
                                  minute: "2-digit",
                                }
                              )}
                            </p>
                            <p className="flightInfoPara4">
                              {formatTime(e.ArrivalDateTime)}
                            </p>
                            <p className="flightInfoPara4">
                              {e.DestinationAirport.airport_name}
                            </p>
                            {e.DestinationAirportTerminal && (
                              <p className="flightInfoPara4">
                                Terminal - {e.DestinationAirportTerminal}
                              </p>
                            )}
                            {/* {e.Destination.Airport.Terminal && (
                              <p className="flightInfoPara4">
                                Terminal - {e.Destination.Airport.Terminal}
                              </p>
                            )} */}
                          </div>
                        </div>
                        {indexx < flight.AirSegments[1].length - 1 && (
                          // <div className="inner_booking">
                          <div
                            className="layover_time"
                            style={{ marginBlock: "20px" }}
                          >
                            <div className="layover_txt">
                              Layover:
                              <span>
                                {formatLayoverTime(
                                  e.ArrivalDateTime,
                                  flight.AirSegments[1][indexx + 1]
                                    .DepartureDateTime
                                )}
                              </span>
                            </div>
                          </div>
                          // </div>
                        )}
                      </div>
                    ))}
                  </div>
                </div>
              </TabPanel>
              <TabPanel value="2">
                <div className="flightInfoMainDiv6 d-flex">
                  {flight && (
                    <div className="flightInfoInnerDiv6">
                      <div
                        className="flightInfoInnerInnerDiv"
                        style={{ border: "none", flexWrap: "wrap" }}
                      >
                        <div
                          style={{
                            width: "100%",
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <p className="flightInfoPara7">Adult</p>
                          <p>
                            {flight.AirlineFares[0].PexFareDetails[0].TotPax}
                          </p>
                        </div>
                        <div
                          style={{
                            width: "100%",
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <p className="flightInfoPara7">Base Fare</p>
                          <p>
                            ₹
                            {flight.AirlineFares[0].PexFareDetails[0].BaseFare +
                              flight.AirlineFares[1].PexFareDetails[0].BaseFare}
                          </p>
                        </div>
                        <div
                          style={{
                            width: "100%",
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <p className="flightInfoPara7">Tax</p>
                          <p>
                            ₹
                            {flight.AirlineFares[0].PexFareDetails[0].TaxFare +
                              flight.AirlineFares[1].PexFareDetails[0].TaxFare + agentIm + adminIm + agentIm + adminIm}
                          </p>
                        </div>
                        {/* <div
                          style={{
                            width: "100%",
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <p className="flightInfoPara7">Other Charges</p>
                          <p>₹{}</p>
                        </div> */}
                        <div
                          style={{
                            width: "100%",
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <p className="flightInfoPara7">Total</p>
                          <b>
                            ₹
                            {flight.AirlineFares[0].PexFareDetails[0]
                              .GrossFare +
                              agentIm + adminIm +
                              flight.AirlineFares[1].PexFareDetails[0]
                                .GrossFare +
                                agentIm + adminIm}
                          </b>
                        </div>
                      </div>
                      {flight.AirlineFares[0].PexFareDetails[1] && (
                        <div
                          className="flightInfoInnerInnerDiv"
                          style={{ border: "", flexWrap: "wrap" }}
                        >
                          <div
                            style={{
                              width: "100%",
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <p className="flightInfoPara7">Child</p>
                            <p>
                              {flight.AirlineFares[0].PexFareDetails[1].TotPax +
                                flight.AirlineFares[1].PexFareDetails[1].TotPax}
                            </p>
                          </div>
                          <div
                            style={{
                              width: "100%",
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <p className="flightInfoPara7">Base Fare</p>
                            <p>
                              ₹
                              {flight.AirlineFares[0].PexFareDetails[1]
                                .BaseFare +
                                flight.AirlineFares[1].PexFareDetails[1]
                                  .BaseFare}
                            </p>
                          </div>
                          <div
                            style={{
                              width: "100%",
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <p className="flightInfoPara7">Tax</p>
                            <p>
                              ₹
                              {flight.AirlineFares[0].PexFareDetails[1]
                                .TaxFare +
                                flight.AirlineFares[1].PexFareDetails[1]
                                  .TaxFare}
                            </p>
                          </div>
                          <div
                            style={{
                              width: "100%",
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <p className="flightInfoPara7">Total</p>
                            <b>
                              ₹
                              {flight.AirlineFares[0].PexFareDetails[1]
                                .BaseFare +
                                flight.AirlineFares[0].PexFareDetails[1]
                                  .TaxFare +
                                flight.AirlineFares[1].PexFareDetails[1]
                                  .BaseFare +
                                flight.AirlineFares[1].PexFareDetails[1]
                                  .TaxFare}
                            </b>
                          </div>
                        </div>
                      )}
                      {flight.AirlineFares[0].PexFareDetails[2] && (
                        <div
                          className="flightInfoInnerInnerDiv"
                          style={{ border: "", flexWrap: "wrap" }}
                        >
                          <div
                            style={{
                              width: "100%",
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <p className="flightInfoPara7">Infant</p>
                            <p>
                              {flight.AirlineFares[0].PexFareDetails[2].TotPax +
                                flight.AirlineFares[1].PexFareDetails[2].TotPax}
                            </p>
                          </div>
                          <div
                            style={{
                              width: "100%",
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <p className="flightInfoPara7">Base Fare</p>
                            <p>
                              ₹
                              {flight.AirlineFares[0].PexFareDetails[2]
                                .BaseFare +
                                flight.AirlineFares[1].PexFareDetails[2]
                                  .BaseFare}
                            </p>
                          </div>
                          <div
                            style={{
                              width: "100%",
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <p className="flightInfoPara7">Tax</p>
                            <p>
                              ₹
                              {flight.AirlineFares[0].PexFareDetails[2]
                                .TaxFare +
                                flight.AirlineFares[1].PexFareDetails[2]
                                  .TaxFare}
                            </p>
                          </div>
                          <div
                            style={{
                              width: "100%",
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <p className="flightInfoPara7">Total</p>
                            <b>
                              ₹
                              {flight.AirlineFares[0].PexFareDetails[2]
                                .GrossFare +
                                agentIm + adminIm +
                                agentIm + adminIm +
                                flight.AirlineFares[1].PexFareDetails[2]
                                  .GrossFare}
                            </b>
                          </div>
                        </div>
                      )}
                      <div className="flightInfoMainInner1">
                        <p className="flightInfoPara8">Total (Base Fare)</p>
                        <p className="flightInfoPara9">
                          {/* {flight.Fare.Currency} */}₹
                          {flight.AirlineFares[0].BaseFare +
                            flight.AirlineFares[1].BaseFare}
                        </p>
                      </div>
                      <div className="flightInfoMainDivInner4">
                        <p className="flightInfoPar10">Total Tax & Surcharges</p>
                        <p className="flightInfoPara9">
                          {/* {flight.Fare.Currency}  */}₹
                          {flight.AirlineFares[0].TaxFare +
                            flight.AirlineFares[0].ServiceCharge +
                            agentIm + adminIm +
                            agentIm + adminIm +
                            flight.AirlineFares[1].TaxFare +
                            flight.AirlineFares[1].ServiceCharge}
                        </p>
                      </div>
                      <div className="flightInfoMainInner1">
                        <p className="flightInfoPara8">
                          Total (Fee + Surcharge)
                        </p>
                        <p className="flightInfoPara9">
                          {/* {flight.Fare.Currency}  */}₹
                          {flight.AirlineFares[0].GrossFare +
                            agentIm + adminIm +
                            flight.AirlineFares[1].GrossFare +
                            agentIm + adminIm}
                        </p>
                      </div>
                    </div>
                  )}
                  <div className="flightInfoMainDiv15">
                    {fareRules ? (
                      <div
                        className="left_gtk fareRule_style"
                        style={{
                          maxHeight: "300px",
                          overflow: "auto",
                          scrollbarWidth: "thin",
                        }}
                      >
                        <div>
                          <div>
                            <ul
                              style={{ padding: "10px" }}
                              className="fare-rule_div"
                            >
                              <li
                                dangerouslySetInnerHTML={{ __html: fareRules }}
                              ></li>
                              {/* {fareRule.map((item, index) => (
                  <li key={index} className="m-info-tips2_item">
                    <p className="txt-sb">{item}</p>
                  </li>
                ))} */}
                            </ul>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div>No Fare Rule Found</div>
                    )}
                  </div>
                </div>
              </TabPanel>
              <TabPanel value="3">
                {/* <Container> */}

                <div className="flightInfortabPanel1">
                  <Row
                    style={{
                      margin: "0px",
                      borderBottom: "1px solid #80808036",
                      alignItems: "center",
                    }}
                  >
                    {/* <div className='flightInfoPanel2'> */}
                    <Col>
                      {/* <div className='flightInfoPanel3'> */}
                      <p>Airline</p>
                      {/* </div> */}
                    </Col>
                    <Col>
                      {/* <div className='flightInfoPanel3'> */}
                      <p>Check-in Baggage</p>
                      {/* </div> */}
                    </Col>
                    <Col>
                      {/* <div className='flightInfoPanel4'> */}
                      <p>Cabin Baggage</p>
                      {/* </div> */}
                    </Col>
                    {/* </div> */}
                  </Row>
                  <Row
                    style={{
                      margin: "0px",
                      marginBottom: "5px",
                      border: "1px solid #80808036",
                      alignItems: "center",
                    }}
                  >
                    {/* <div className='flightInfoPanel5'> */}
                    <Col>
                      <div
                        className=""
                        style={{
                          display: "flex",
                          gap: "5px",
                          padding: "5px",
                          alignItems: "center",
                          justifyContent: "start",
                        }}
                      >
                        <img
                          src={`/Images/AirlineLogo/${flight.AirlineCode}.gif`}
                          alt=""
                          className="flightInfoPanelImage"
                        />
                        <div className="flightInfoPara9">
                          <p style={{ fontSize: "13px" }}>
                            {flight.AirSegments[0][0].AirlineName}
                          </p>
                          <p
                            style={{
                              fontSize: "12px",
                              lineHeight: "0cm",
                              marginTop: "7px",
                            }}
                          >
                            {flight.AirSegments[0][0].FlightNumber}
                          </p>
                        </div>
                      </div>
                    </Col>
                    <Col>
                      {/* <div className='flightInfoPara7'> */}
                      <p>
                        {flight.AirSegments[0][0].BaggageAllowed.CheckInBaggage}
                      </p>
                      {/* </div> */}
                    </Col>
                    <Col>
                      {/* <div className='flightInfoPanel8'> */}
                      <p>
                        {flight.AirSegments[0][0].BaggageAllowed.HandBaggage}
                      </p>
                      {/* </div> */}
                    </Col>

                    {/* </div> */}
                  </Row>
                  <div className="flightInfoPanel9">
                    <ul className="flightInfoPanel0">
                      <li className="flightInforUnderList">
                        <HiMiniHandThumbUp className="icons" />
                        Baggage information mentioned above is obtained from
                        airline's reservation system, Yatri Service does not
                        guarantee the accuracy of this information.
                      </li>
                      <li className="flightInforUnderList">
                        <HiMiniHandThumbUp className="icons" />
                        The baggage allowance may vary according to stop-overs,
                        connecting flights. changes in airline rules. etc.
                      </li>
                    </ul>
                  </div>
                </div>
                {/* </Container> */}
              </TabPanel>
              <TabPanel value="4">
                <div className="flightInfoMainDiv8" style={{ padding: "10px" }}>
                  <h3 className="flightInfoPara9">Terms & Conditions</h3>
                  <ul className="flightInfoUnderline2">
                    <li className="flightInforUnderList">
                      <HiMiniHandThumbUp className="icons" />
                      Total Rescheduling Charges Airlines Rescheduling fees Fare
                      Difference if applicable + Yatri Service Fees.
                    </li>
                    <li className="flightInforUnderList">
                      <HiMiniHandThumbUp className="icons" />
                      The airline cancel reschedule fees is indicative and can
                      be changed without any prior notice by the airlines..
                    </li>
                    <li className="flightInforUnderList">
                      <HiMiniHandThumbUp className="icons" />
                      Yatri Service does not guarantee the accuracy of cancel
                      reschedule fees..
                    </li>
                    <li className="flightInforUnderList">
                      <HiMiniHandThumbUp className="icons" />
                      Partial cancellation is not allowed on the flight tickets
                      which are book under special round trip discounted fares..
                    </li>
                    <li className="flightInforUnderList">
                      <HiMiniHandThumbUp className="icons" />
                      Airlines doesnt allow any additional baggage allowance for
                      any infant added in the booking
                    </li>
                    <li className="flightInforUnderList">
                      <HiMiniHandThumbUp className="icons" />
                      In certain situations of restricted cases, no amendments
                      and cancellation is allowed
                    </li>
                    <li className="flightInforUnderList">
                      <HiMiniHandThumbUp className="icons" />
                      Airlines cancel reschedule should be reconfirmed before
                      requesting for a cancellation or amendment
                    </li>
                  </ul>
                </div>
              </TabPanel>
            </TabContext>
          </Box>
        </div>
      ) : null}
    </>
  );
};
